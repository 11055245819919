<template>
  <div>
    <title-header :title="title" :buttons="headerButtons"></title-header>

    <v-row class="mr-5">
      <v-spacer></v-spacer>
      <v-col justify="end" md="4">
        <v-text-field v-model="search" class="ml-5" append-icon="mdi-magnify" label="Buscar" single-line clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="rols" :search="search" class="elevation-1 pl-10 pr-10">
    </v-data-table>

  </div>
</template>
<script>
import TitleHeader from '@/components/app/title-header/title-header'
export default {
  name: 'rols-list',
  props: {},
  data() {
    return {
      title: 'Roles',
      headerButtons: [
        {
          text: 'Nuevo rol',
          urlSlug: 'rol/create'
        }
      ],
      search: '',
      headers: [
        { text: 'Nombre', value: 'displayName' },
        { text: 'Descripcion', value: 'email' },
        { text: '', value: 'buttons', width: 265 }
      ],
      rols: []
    }
  },
  components: {
    TitleHeader
  },
  created() {

  }
}
</script>
